import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {VerificarUsuarioGuard} from "./guard/verificar-usuario.guard";

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate:[VerificarUsuarioGuard]
  },
  // {
  //   path: 'home',
  //   // redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'paciente',
    loadChildren: () => import('./paciente/paciente.module').then( m => m.PacientePageModule),
    canActivate:[VerificarUsuarioGuard]
  },
  {
    path: 'datos',
    loadChildren: () => import('./datos/datos.module').then( m => m.DatosPageModule),
    canActivate:[VerificarUsuarioGuard]
  },
  {
    path: 'predicciones',
    loadChildren: () => import('./predicciones/predicciones.module').then( m => m.PrediccionesPageModule),
    canActivate:[VerificarUsuarioGuard]
  },
  {
    path: 'recomendaciones-pautas',
    loadChildren: () => import('./recomendaciones-pautas/recomendaciones-pautas.module').then( m => m.RecomendacionesPautasPageModule),
    canActivate:[VerificarUsuarioGuard]
  },
  {
    path: 'recomendaciones-dietas',
    loadChildren: () => import('./recomendaciones-dietas/recomendaciones-dietas.module').then( m => m.RecomendacionesDietasPageModule),
    canActivate:[VerificarUsuarioGuard]
  },
  {
    path: 'paciente-resultado',
    loadChildren: () => import('./paciente-resultado/paciente-resultado.module').then( m => m.PacienteResultadoPageModule),
    canActivate:[VerificarUsuarioGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
