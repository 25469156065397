import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { MatDatepickerModule  } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


import {MaterialExampleModule} from '../material.module';
import {MatPaginatorIntl} from '@angular/material/paginator';
import { getSpanishPaginatorIntl } from '../spanish-paginator-intl';
// import { IonicSelectableModule } from 'ionic-selectable';

import {MatSelectModule} from '@angular/material/select';
import {HttpsResponseInterceptor} from "./interceptors/InterceptorModule";



//let IonicStorageModule;

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    MatDatepickerModule,
    MatNativeDateModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // IonicSelectableModule,
    // IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }
    }),
    MaterialExampleModule,
    MatSelectModule
  ],
  exports: [
    MatPaginatorIntl,
    MatSelectModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    {provide: HTTP_INTERCEPTORS, useClass: HttpsResponseInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
