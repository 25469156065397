import { Injectable, NgModule } from '@angular/core';

import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse, HttpHeaders, HttpClient
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import {GlobalService} from "../../services/global.service";

@Injectable()
export class HttpsResponseInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        public global: GlobalService,
        public http: HttpClient) {

    }


    private _goLogin() {
        this.router.navigate(['/']).then(() => {
            try {
                this.global.ocultaLoader();
            } catch (e) {
                console.log(e);
            }
        });
    }

    goLogin() {
        //this.authenticationService.logout();
        this._goLogin();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(map(event => {
                return event;
            }),
                catchError((err, caught) => {
                    if (err instanceof HttpErrorResponse && err.status == 401) {
                        this.goLogin();
                    }
                    throw (err);
                }));
    }
}
