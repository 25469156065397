import { Component } from '@angular/core';
import {Platform} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
//import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  currentPageTitle = 'Dashboard';
  selectedIndex = 0;

  appPages = [
    {
      title: 'Inicio',
      url: '/home',
      icon: 'home',
      visible:true
    },
    {
      title: 'Nuevo Paciente',
      url: '/paciente',
      icon: 'person_add',
      visible:false
    },
    {
      title: 'Visualización de Datos',
      url: '/datos',
      icon: 'assessment',
      visible:true
    }
    ,
    {
      title: 'Predicciones',
      url: '/predicciones',
      icon: 'psychology',
      visible:false
    }
    ,
    {
      title: 'Cerrar Sesión',
      url: '/',
      icon: 'logout',
      visible:true
    }
  ];


  constructor(
    private platform: Platform,
   // private statusBar: StatusBar,
    public translateService: TranslateService,
   // private authenticationService: AuthService,
    private router: Router,
    //private storage: Storage,
    //private global: GlobalService,
    //private api: ApiProvider
  ) {
    let langDef = 'es';
    if (localStorage.getItem('idioma')) {
      langDef = localStorage.getItem('idioma');
    } else {
      localStorage.setItem('idioma', langDef);
    }
    this.translateService.setDefaultLang(langDef);
    this.translateService.use(langDef);
    //Parche para las rutas
   /* let ruta=window.location.href.split('/').pop();
    console.log(ruta)
    if(ruta=='home' || ruta==''){
      this.selectedIndex=0;
    }else{
      this.selectedIndex=2;
    }*/
  }
}
