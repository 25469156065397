// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SERVER:'https://nutrigep.merkatu.info',
  SERVERNUTRI:'https://nutrigepcdss.vicomtech.org',
  SERVERRISK:'https://nutrigeprisk.vicomtech.org',
  OAUTH_CLIENT_ID: '3_1a2tw1203adcw8s08s4gw0gooswws8ckwo0s00k4ksoc80ossk',
  OAUTH_CLIENT_SECRET: '4afw44y1bv28gcooc48ckss0so0wgkscgw400g4kgowoosooc8',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
