import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class VerificarUsuarioGuard implements CanActivate {
    constructor( private router: Router) {
    }

    getStorage() {
        const token = localStorage.getItem('access_token');
        if (token !== null && token.length > 0) {
            return true;
        }
        return false;
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const userAuthenticated = this.getStorage();
        // Get the current authentication state from a Service!
        if (userAuthenticated) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}
