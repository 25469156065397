import {AlertController, LoadingController} from "@ionic/angular";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
private loaderShowed:boolean=false;
  constructor(
    public loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {
  }
  async muestraLoader() {
    // AQUÍ SE GENERA EL LOADING.
    this.loaderShowed = true;
    return await this.loadingCtrl.create({
      duration: 20000,
      message: '<ion-spinner name="crescent"></ion-spinner>', // y esto es lo que se muestra.
      cssClass: 'loader', // esta es la clase para el css
      spinner: null,
    }).then(a => {
      a.present().then(() => {
        if (!this.loaderShowed) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async ocultaLoader() {
    const loader = this.loadingCtrl;
    setTimeout(function () {
      this.loaderShowed = false;
      return loader.dismiss().then(() => console.log('dismissed'));
    }, 100  );
  }
  async presentAlert(titulo, texto, boton) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      message: texto,
      buttons: [boton]
    });
    await alert.present();
  }
}
